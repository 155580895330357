import "./footer.css";

const Footer = () => {
  return (
<footer id="footer" className="bg-gray border-0 mt-4">
  <div className="container">
    <div className="footer-widgets-wrap">
      <div className="row justify-content-between">
        <div className="col-lg-6 mb-5 mb-lg-0">
          <div className="widget clearfix">
            <div class="fw-semibold font-primary color ls3 h3 text-uppercase mb-4">
              CAPITAL AFRICA NETWORK<span class="color-2">.</span>
            </div>
            <p className="mb-4">
              We provide our clients with reliable, cost-effective advice on business development, risk management, project financing, and a range of other services that leverage our deep understanding of the intricate African business environment.
            </p>
          </div>
        </div>
        <div className="col-lg-6 d-flex justify-content-end">
          <div className="row w-100">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <h3 className="">Menu</h3>
              <ul className="widget_links widget-li-noicon">
                <li className="mb-2 fw-medium">
                  <a href="/">Home</a>
                </li>
                <li className="mb-2 fw-medium">
                  <a href="/services">Services</a>
                </li>
                <li className="mb-2 fw-medium">
                  <a href="/about">About</a>
                </li>
                <li className="mb-2 fw-medium">
                  <a href="/contact">Contact</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <h3 className="">Contact</h3>
              <div class="contact-info d-flex align-items-center">
                <i class="icon-line-mail me-2"></i>
                <div>
                  <a href="mailto:info@capitalafricanetwork.com">
                    info@capitalafricanetwork.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="copyrights" className="bg-gray">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col">
          <span className="text-black-50">
            © 2024 All Rights Reserved by{" "}
            <a
              href="https://diplomatechnology.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Diplomatech
            </a>
            .
          </span>
        </div>
      </div>
    </div>
  </div>
</footer>

  );
};

export default Footer;
