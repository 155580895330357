import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./style.scss";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import HeaderNav from "./components/HeaderNav/HeaderNav";
import Footer from "./components/footer/Footer";



function App() {
  return (
    <BrowserRouter>
      <HeaderNav />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/services" exact component={Services} />
        <Route path="/contact" exact component={Contact} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
