import React from 'react'
import CallToAction from '../components/CallToAction'

const About = () => {
  return (
<section id="content">
  <div className="content-wrap py-0">
    <div className="block-hero-21" style={{ padding: "130px 0px" }}>
      <div className="container">
        <div className="row flex-row-reverse justify-content-between align-items-center">
          <div className="col-lg-6">
            <img
              src="images/invest.jpg"
              alt="Image"
              className="z-1 position-relative img-fluid"
              style={{ marginBottom: "-60px" }}
            />
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0">
            <h4 className="mb-0">
              <span className="badge bg-secondary font-body">About Us</span>
            </h4>
            <h4 className="display-6 nott ls0 font-primary color">
              We strive to be the most trusted and reliable international
              consulting firm in Africa.
            </h4>
            <p className="mw-xs fw-normal mb-5 text-larger">
              At Capital Africa Network, we offer a comprehensive range of
              services designed to address your specific business requirements.
            </p>
            <a
              href="/contact"
              className="btn btn-secondary px-5 rounded-pill shadow-sm"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row row-cols-1 row-cols-lg-3 g-4">
      <div className="col">
        <div className="heading-block fancy-title border-bottom-0 title-bottom-border">
          <h4>
            Why choose <span>Us</span>.
          </h4>
        </div>
        <p>
          We provide our clients with reliable, cost-effective advice on business
          development, risk management, project financing, and a range of other
          services that leverage our deep understanding of the intricate African
          business environment.
        </p>
      </div>
      <div className="col">
        <div className="heading-block fancy-title border-bottom-0 title-bottom-border">
          <h4>
            Our <span>Mission</span>.
          </h4>
        </div>
        <p>
          Our team of experienced consultants works closely with you to understand
          your goals and develop tailored solutions that deliver tangible results.
          Whether you are looking to expand your operations, establish strategic
          partnerships, or explore new investment opportunities, we are here to
          guide you every step of the way.
        </p>
      </div>
      <div className="col">
        <div className="heading-block fancy-title border-bottom-0 title-bottom-border">
          <h4>
            What we <span>Do</span>.
          </h4>
        </div>
        <p>
          We are committed to providing our clients with the highest quality of
          service and the latest insights into the African market. We strive to be
          the most trusted and reliable international consulting firm in Africa.
        </p>
      </div>
    </div>
  </div>
  <div className="section m-0">
    <div className="container">
      <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
        <div className="col text-center">
          <img src="./images/1.png" alt="" width={100} />
          <div className="counter counter-lined">
            <span data-to={10}>10</span>
          </div>
          <h5>Partner</h5>
        </div>
        <div className="col text-center">
          <img src="./images/2.png" alt="" width={100} />
          <div className="counter counter-lined">
            <span data-to={150}>150</span>
          </div>
          <h5>Project Accomplished</h5>
        </div>
        <div className="col text-center">
          <img src="./images/3.png" alt="" width={100} />
          <div className="counter counter-lined">
            <span data-to={20}>20</span>
          </div>
          <h5>Investments</h5>
        </div>
        <div className="col text-center">
          <img src="./images/afriii.png" alt="" width={150} height={100} />
          <div className="counter counter-lined">
            <span data-to={15}>15</span>
          </div>
          <h5>African countries</h5>
        </div>
      </div>
    </div>
  </div>
  <CallToAction />
</section>


  )
}

export default About