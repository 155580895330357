import CallToAction from "../components/CallToAction";

const Home = () => {
  return (
    <div>
      <section
        id="slider"
        className="slider-element"
        style={{
          background:
            'url("demos/finance/images/hero-bg.png") center bottom / cover no-repeat rgba(38, 70, 83, 0.17)',
        }}
      >
        <div className="container">
          <div className="row align-items-md-end">
            <div className="col-lg-5 col-md-12 align-self-center flex-column py-6">
              <h3 className="hero-title display-5 fw-bold color font-body text-md">
                Business in Africa
              </h3>
              <p className="my-5">
                Capital Africa Network is an international consulting firm
                specialized in providing business solutions to companies
                operating in Africa.
              </p>
              <a
                href="/contact"
                className="btn btn-lg btn-dark bg-color rounded-pill mb-5 px-5"
              >
                Get Started <i className="icon-line-arrow-right color-2 ms-1" />
              </a>
              <div className="row">
                <div className="col-md-6">
                  <ul className="iconlist mb-0"  style={{fontSize: "14px"}}>
                    <li className="mb-2">
                      <i className="icon-line-check text-smaller bg-color-2 color" />
                      Market Research in Africa
                    </li>
                    <li className="mb-2">
                      <i className="icon-line-check text-smaller bg-color-2 color" />
                      International Business 
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="iconlist mb-0" style={{fontSize: "14px"}}>
                    <li className="mb-2" >
                      <i className="icon-line-check text-smaller bg-color-2 color" />
                      Cross-Cultural Training
                    </li>
                    <li className="mb-2">
                      <i className="icon-line-check text-small bg-color-2 color" />
                      Global African Consulting 
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 dotted-bg">
              <img
                src="images/africa.png"
                alt="Image 1"
                className="d-flex align-self-end mt-0 z-1 mx-auto w-100 position-relative"
              />
            </div>
          </div>
        </div>
      </section>
      <div
        className="section section-about bg-transparent m-0"
        style={{ padding: "120px 0px" }}
      >
        <div className="container">
          <div className="row align-items-center">
          <div className="col-md-6">
              <div className="mb-4">
                <h4 className="mb-0">
                  <span className="badge bg-secondary font-body">About Us</span>
                </h4>
                <div className="heading-block">
                  <h2 className="fw-normal ls0 nott mb-3 font-primary">
                    International Consulting <br /> in Africa.
                  </h2>
                </div>
                <p>
                  Our team of experienced consultants works closely with you to
                  understand your goals and develop tailored solutions that
                  deliver tangible results. Whether you are looking to expand
                  your operations, establish strategic partnerships, or explore
                  new investment opportunities, we are here to guide you every
                  step of the way.
                </p>
              </div>
              <a
                href="/about"
                className="button button-large ls0 nott ms-0 mt-2 clearfix"
              >
                About Us
              </a>
            </div>
            <div className="col-md-6 mb-4 mb-md-0">
              <img
                src="images/afric.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <section id="content">
  <div className="content-wrap py-5">
    <div className="container mw-md py-5">
      <div className="row justify-content-between align-items-center col-mb-30">
        <div className="col-md-2 text-center">
          <h3 className="display-2 fw-light mb-2 color">4.8</h3>
          <div className="mb-2 color-2">
            <i className="icon-star3" />
            <i className="icon-star3" />
            <i className="icon-star3" />
            <i className="icon-star3" />
            <i className="icon-star3" />
          </div>
          <span>20,321 Ratings</span>
        </div>
        <div className="col-md-4">
          <h5 className="text-uppercase font-body ls2 color-2">What We Do</h5>
          <h3 className="mb-0 fw-normal">
            We are committed to providing our clients with the highest quality
            of service and the latest insights into the African market.
          </h3>
        </div>
        <div className="col-md-5 mt-4 mt-md-0">
          <blockquote className="py-0 mb-0">
            <p>
              "Capital Africa Network provided excellent consulting services for
              my business in Africa. Their team was knowledgeable, professional,
              and responsive. They helped me navigate complex regulations and
              made the entire process seamless. Thank you for your outstanding
              work! "
            </p>
            <footer className="blockquote-footer font-primary">Emily</footer>
          </blockquote>
        </div>
      </div>
    </div>
    <div className="heading-block mw-xs mx-auto text-center mb-6">
      <h5 className="font-body text-uppercase ls2 color-2">Services</h5>
      <h3 className="color nott ls0">What We Do</h3>
    </div>
    <div className="section">
      <div className="container">
        <div className="row col-mb-50">
          <div className="col-md-4">
            <div className="feature-box fbox-plain">
              <div className="fbox-content">
                <h3>Cross-Cultural Training</h3>
                <p>
                  Our customized training programs will help you and your team
                  effectively navigate cultural differences, avoid
                  misunderstandings, and achieve success in your African
                  operations.
                </p>
                <a
                  href="/services"
                  className="button button-large ls0 nott ms-0 mt-2 clearfix rounded"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature-box fbox-plain">
              <div className="fbox-content">
                <h3>Global African Consulting Firm </h3>
                <p>
                  We provide international consulting services to companies in
                  Africa, helping them to develop and execute efficient
                  strategies to maximize their growth and profitability.
                </p>
                <a
                  href="/services"
                  className="button button-large ls0 nott ms-0 mt-2 clearfix"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature-box fbox-plain">
              <div className="fbox-content">
                <h3>International Business Strategy</h3>
                <p>
                  Our team of experts will help you develop a comprehensive
                  approach to doing business in Africa, including market entry,
                  localization, and compliance.
                </p>
                <a
                  href="/about"
                  className="button button-large ls0 nott ms-0 mt-2 clearfix rounded"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="clear" />
    <div className="container my-5">
      <div className="heading-block mw-xs mx-auto text-center mb-6">
        <h5 className="font-body text-uppercase ls2 color-2">Testimonials</h5>
        <h3 className="color nott ls0">What Our Clients say</h3>
      </div>
      <div className="row gutter-md-50">
        <div className="col-lg-4 px-lg-5 mt-5 mt-lg-0">
          <div className="mb-4 color-2">
            <i className="icon-star3" />
            <i className="icon-star3" />
            <i className="icon-star3" />
            <i className="icon-star3" />
            <i className="icon-star3" />
          </div>
          <p className="color op-06 fw-medium">
            "Capital Africa Network provided excellent consulting services for
            my business in Africa. Their team was knowledgeable, professional,
            and responsive. They helped me navigate complex regulations and made
            the entire process seamless. Thank you for your outstanding work!"
          </p>
          <div className="d-flex align-items-center mt-4">
            <div className="ms-3">
              <h4 className="font-body mb-2 fw-bold h6">Emily</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 px-lg-5 mt-5 mt-lg-0">
          <div className="mb-4 color-2">
            <i className="icon-star3" />
            <i className="icon-star3" />
            <i className="icon-star3" />
            <i className="icon-star3" />
            <i className="icon-star3" />
          </div>
          <p className="color op-06 fw-medium">
            "Capital Africa Network provided invaluable guidance in navigating
            the unique challenges of international business in Africa. We would
            definitely recommend their services."
          </p>
          <div className="d-flex align-items-center mt-4">
            <div className="ms-3">
              <h4 className="font-body mb-2 fw-bold h6">Fiona Gray</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 px-lg-5 mt-5 mt-lg-0">
          <div className="mb-4 color-2">
            <i className="icon-star3" />
            <i className="icon-star3" />
            <i className="icon-star3" />
            <i className="icon-star3" />
            <i className="icon-star3" />
          </div>
          <p className="color op-06 fw-medium">
            "The Capital Africa Network provided us with invaluable guidance on
            our project in Africa. Their international consulting expertise was
            extremely helpful in navigating the complexities of the African
            market. "
          </p>
          <div className="d-flex align-items-center mt-4">
            <div className="ms-3">
              <h4 className="font-body mb-2 fw-bold h6">Brian N</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="clear" />
  </div>
</section>
<CallToAction />
    </div>
  );
};

export default Home;
