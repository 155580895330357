import React from 'react'

const contact = () => {
  return (
    <div
    className="container bg-color p-5 p-lg-6 mb-6"
    style={{ borderRadius: 10 }}
  >
    <div className="row justify-content-around">
      <div className="col-lg-7 ">
        <h3 className="mb-4 display-4 nott ls0 fw-semibold text-white">Contact Us</h3>
        <p className="mw-xs lead op-07 mb-5 text-white">
          Energistically syndicate team building synergy after efficient human
          capital. Assertively underwhelm sticky solutions.
        </p>
        <div className="feature-box fbox-sm mb-4">
          <div className="fbox-icon">
            <i className="icon-line-map-pin bg-color-2 color" />
          </div>
          <div className="fbox-content">
            <h4 className="nott font-body text-white fw-normal mb-0">
              New York
              <br />
            </h4>
          </div>
        </div>
        {/* <div className="feature-box fbox-sm align-items-center mb-4">
          <div className="fbox-icon">
            <i className="icon-line-phone-call bg-color-2 color" />
          </div>
          <div className="fbox-content">
            <h4 className="nott font-body fw-normal mb-0">
              <a className="text-white" href="tel:+122-55412474">
                (1) 22 55412474
              </a>
            </h4>
          </div>
        </div> */}
        <div className="feature-box fbox-sm align-items-center mb-4">
          <div className="fbox-icon">
            <i className="icon-line-mail bg-color-2 color" />
          </div>
          <div className="fbox-content">
            <h4 className="nott font-body fw-normal mb-0">
              <a className="text-white" href="mailto:noreply@canvas.com">
              info@capitalafricanetwork.com
              </a>
            </h4>
          </div>
        </div>
        <div className="feature-box fbox-sm">
          <div className="fbox-icon">
            <i className="icon-line-clock bg-color-2 color" />
          </div>
          <div className="fbox-content">
            <h4 className="nott font-body fw-normal mb-0">
              <a className="text-white" href="capitalafricanetwork.com">
                Mon-Fri: 10:00-18:00 <br /> Sat-Sun: 10:00-14:00
              </a>
            </h4>
          </div>
        </div>
      </div>
     
    </div>
  </div>
  
  )
}

export default contact