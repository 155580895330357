import "./HeaderNav";

const HeaderNav = () => {
  return (
    <div>
      <div id="top-bar" class="dark bg-color">
        <div class="container">
          <div class="row justify-content-center justify-content-lg-between">
            <div class="col-12 col-md-auto"></div>
            <div class="col-12 col-md-auto">
              <div class="top-links text-center">
                <ul class="top-links-container">
                  <li class="top-links-item">
                    <a href="mailto:info@capitalafricanetwork.com">
                      <i
                        className="icon-line-mail  text-white me-2 position-relative"
                        style={{ top: 1 }}
                      />
                      info@capitalafricanetwork.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header
        id="header"
        className="sticky-on-scrollup fixed-top show-sticky-onscroll"
      >
        <div id="header-wrap">
          <div className="container">
            <div className="header-row justify-content-between">
              <div id="logo" className="mx-0 me-lg-5 order-2 order-lg-1">
                <a
                  href="/"
                  className="standard-logo"
                  data-dark-logo="images/capital.png"
                >
                  <img src="images/capital.png" alt="Capital Logo" />
                </a>
                <a
                  href="/"
                  className="retina-logo"
                  data-dark-logo="images/capital.png"
                >
                  <img src="images/capital.png" alt="Capital Logo" />
                </a>
              </div>
              <div id="primary-menu-trigger" className="order-1">
                <svg className="svg-trigger" viewBox="0 0 100 100">
                  <path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
                  <path d="m 30,50 h 40" />
                  <path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
                </svg>
              </div>
              <div className="header-misc order-3">
                <a
                  href="/contact"
                  className="btn text-larger btn-dark bg-color px-4 py-2 rounded-pill"
                >
                  <i className="icon-line-message-circle color-2" />
                  <span className="d-none d-md-inline ms-2">
                    Get Consultant
                  </span>
                </a>
              </div>
              <nav
                className="primary-menu me-lg-auto with-arrows order-12 order-lg-2"
                style={{ alignItems: "center" }}
              >
                <ul className="menu-container">
                  <li className="menu-item current">
                    <a className="menu-link" href="/">
                      <div>Home</div>
                    </a>
                  </li>
                  <li className="menu-item">
                    <a className="menu-link" href="/services">
                      <div>Services</div>
                    </a>
                  </li>
                  <li className="menu-item">
                    <a className="menu-link" href="/about">
                      <div>About</div>
                    </a>
                  </li>
                  <li className="menu-item">
                    <a className="menu-link" href="/contact">
                      <div>Contact</div>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="header-wrap-clone" />
      </header>
    </div>
  );
};

export default HeaderNav;
