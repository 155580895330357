import React from "react";

const CallToAction = () => {
  return (
    <div
      className="container mt-5 rounded-6"
      style={{ backgroundColor: "rgba(38, 70, 83, 0.106)" }}
    >
      <div className="row align-items-center justify-content-center justify-content-lg-between p-5 p-md-6">
        <div className="col-lg-6 mb-5 mb-lg-0">
          <h5 className="font-body h5 mb-3 op-07">
            Business opportunities in Africa
          </h5>
          <div class="fw-semibold font-primary color ls3 h2 mb-4">
            We provide tailored solutions to your business needs, helping you
            reach your goals<span class="color-2">.</span>
          </div>
        </div>
        <div className="col-lg-auto">
          <a
            href="/contact"
            className="btn btn-secondary  text-black px-3 py-2 px-md-5 py-md-3 bg-color-2 rounded-pill ms-lg-2"
          >
            <i
              className="icon-line-mail color me-2 position-relative"
              style={{ top: 1 }}
            />
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
