import CallToAction from "../components/CallToAction";

const services = () => {
  return (
<>
  <section
    id="slider"
    className="slider-element py-6 block-hero-6"
    style={{
      backgroundImage:
        "radial-gradient(circle at right top, rgb(221, 221, 221) 0%, rgb(221, 221, 221) 28%, rgb(216, 89, 118) 28%, rgb(216, 89, 118) 45%, rgb(241, 199, 122) 45%, rgb(241, 199, 122) 63%, rgb(239, 233, 226) 63%, rgb(239, 233, 226) 100%)",
    }}
  >
    <div className="container">
      <div className="row align-items-center justify-content-between">
        <div className="col-md-6">
          <div className="fw-semibold font-primary color ls3 h2 text-uppercase mb-4">
            Capital Africa Network<span className="color-2">.</span>
          </div>
          <p className="lead text-black-50">
            Capital Africa Network is an international consulting firm that specializes in providing comprehensive services in Africa.
          </p>
          <a
            href="/contact"
            className="button button-3d button-large rounded-pill bg-danger mt-3 mx-0"
          >
            Contact Us
          </a>
        </div>
        <div className="col-md-5 mt-5 mt-md-0">
          <img
            src="images/Africa_(orthographic_projection).svg.png"
            alt="image"
            className="rounded-6 shadow border border-width border-width-5 img-fluid"
          />
        </div>
      </div>
    </div>
  </section>
  <section id="content">
    <div className="content-wrap py-0" />
  </section>
  <div className="section bg-color" style={{ padding: "100px 0px" }}>
    <div className="container">
      <div className="heading-block mw-xs mx-auto text-center mb-6">
        <h5 className="font-body display-3 text-uppercase ls2 color-2">
          Our services
        </h5>
        <p className="text-white nott ls0">
          We develop tailored strategies and solutions to help our clients navigate the complex African business environment.
        </p>
      </div>
      <div className="row row-cols-1 row-cols-md-2 g-4 justify-content-center">
        <div className="col">
          <div className="card bg-transparent border-0">
            <img
              src="images/startup-afrique.jpg"
              className="card-img-top img-fluid rounded"
              style={{height: "300px", width: "100%"}}
              alt="Market Research and Analysis"
            />
            <div className="card-body">
              <div className="fw-semibold font-primary text-white ls3 h3 text-center">
                Market Research and Analysis
              </div>
              <div className="card-text text-white" style={{ fontSize: "15px" }}>
                We provide in-depth market research and analysis to help you gain valuable insights into African markets. Our team examines industry trends, consumer behavior, competitive landscapes, and regulatory frameworks, enabling you to make informed business decisions.
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card bg-transparent border-0">
            <img
              src="images/1252.avif"
              className="card-img-top img-fluid rounded"
              style={{height: "300px", width: "100%"}}
              alt="Business Development"
            />
            <div className="card-body">
              <div className="fw-semibold font-primary text-white ls3 h3 text-center">
                Business Development
              </div>
              <div className="card-text text-white" style={{ fontSize: "15px" }}>
                We assist in developing and executing effective business development strategies tailored to your target markets in Africa. Our experts identify potential partners, explore new avenues for growth, and help you build strong networks within the African business community.
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card bg-transparent border-0">
            <img
              src="images/11.jpg"
              className="card-img-top img-fluid rounded"
              style={{height: "300px", width: "100%"}}
              alt="Investment Facilitation"
            />
            <div className="card-body">
              <div className="fw-semibold font-primary text-white ls3 h3 text-center">
                Investment Facilitation
              </div>
              <div className="card-text text-white" style={{ fontSize: "15px" }}>
                We specialize in connecting investors with promising investment opportunities in Africa. Our team evaluates projects, conducts due diligence, and facilitates negotiations to ensure successful investment ventures.
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card bg-transparent border-0">
            <img
              src="images/1212.webp"
              className="card-img-top img-fluid rounded"
              style={{height: "300px", width: "100%"}}
              alt="Partnership and Networking"
            />
            <div className="card-body">
              <div className="fw-semibold font-primary text-white ls3 h3 text-center">
                Partnership and Networking
              </div>
              <div className="card-text text-white" style={{ fontSize: "15px" }}>
                We facilitate strategic partnerships and collaborations between international companies and African enterprises. Through our extensive network of industry contacts, we connect you with potential business partners, suppliers, distributors, and local stakeholders, fostering mutually beneficial relationships.
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card bg-transparent border-0">
            <img
              src="images/1000.avif"
              className="card-img-top img-fluid rounded"
              style={{height: "300px", width: "100%"}}
              alt="Project Management"
            />
            <div className="card-body">
              <div className="fw-semibold font-primary text-white ls3 h3 text-center">
                Project Management
              </div>
              <div className="card-text text-white" style={{ fontSize: "15px" }}>
                We provide end-to-end project management services to ensure seamless execution and successful outcomes. Our team oversees project planning, implementation, and monitoring, keeping you informed and in control throughout the process.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CallToAction />
</>

  );
};

export default services;
